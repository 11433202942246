import {copyToClipboard} from './clipboard';

export const copyUrl = async(e: MouseEvent) => {
  e.preventDefault();
  const url = (e.target as HTMLAnchorElement | null)?.href;
  if (url) {
    await copyToClipboard(url)
      .then(() => M.toast({text: 'Link gekopieerd!'}))
      .catch(() => M.toast({text: 'Link kopiëren mislukt'}));
  }
};
