import {defineAsyncComponent} from 'vue';

import LoadingComponent from '@/js/vue/components/LoadingComponent.ts.vue';

type PossibleArguments = Parameters<typeof defineAsyncComponent>[0];
type NotFunction<T> = T extends (...args: never) => unknown ? never : T;
type DefaultArguments = NotFunction<PossibleArguments>;

export const defineDefaultAsyncComponent = (loader: DefaultArguments['loader']) =>
  defineAsyncComponent({
    loader,
    loadingComponent: LoadingComponent,
    delay: 200,
  });
