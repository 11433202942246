// See also https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript

const copyToClipboardLegacy = async(text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  await new Promise<boolean>((resolve) => resolve(document.execCommand('copy')))
    .then((result) => {
      if (!result) {
        throw new Error('Copy of text failed');
      }
    })
    .finally(() => document.body.removeChild(textArea));
};

const copyToClipboardModern = async(text: string) => await navigator.clipboard.writeText(text);

export const copyToClipboard = async(text: string) => (navigator?.clipboard?.writeText !== undefined)
  ? await copyToClipboardModern(text)
  : await copyToClipboardLegacy(text);
