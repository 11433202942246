export const appLocale: Intl.LocalesArgument = 'nl-NL';

export const appCurrency: Intl.NumberFormatOptions['currency'] = 'EUR';

const euroFormatter = new Intl.NumberFormat(appLocale, {
  style: 'currency',
  currency: appCurrency,
});

/**
 * Formats float to an amount-string in euros.
 *
 * @example formatAmount(1.23) => '€ 1,23'
 */
export const formatAmount = (amount: number) => euroFormatter.format(amount);

/**
 * Formats integer (in cents) to an amount-string in euros.
 *
 * @example formatAmountInCents(123) => '€ 1,23'
 */
export const formatAmountInCents = (amount: number) => formatAmount(amount / 100);
