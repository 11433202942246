/* eslint-disable import/order */

import Vue from 'vue';

/**
 * ---------------------
 * Vue components
 * ---------------------
 */

// Self-made components

import {defineDefaultAsyncComponent} from './util/asyncComponent';

Vue.component('LoginForm', defineDefaultAsyncComponent(() => import('./apps/LoginForm.ts.vue')));
Vue.component('RegisterForm', defineDefaultAsyncComponent(() => import('./apps/RegisterForm.vue')));
Vue.component('ConfirmPasswordForm', defineDefaultAsyncComponent(() => import('./apps/ConfirmPasswordForm.ts.vue')));
Vue.component('ResetPasswordForm', defineDefaultAsyncComponent(() => import('./apps/ResetPasswordForm.ts.vue')));
Vue.component('MijnGegevensForm', defineDefaultAsyncComponent(() => import('./apps/MijnGegevensForm.vue')));
Vue.component('ChangePasswordForm', defineDefaultAsyncComponent(() => import('./apps/ChangePasswordForm.ts.vue')));
Vue.component('ChangeEmailForm', defineDefaultAsyncComponent(() => import('./apps/ChangeEmailForm.ts.vue')));
Vue.component('BeschikbaarheidForm', defineDefaultAsyncComponent(() => import('./apps/BeschikbaarheidForm.ts.vue')));
Vue.component('IndelingForm', defineDefaultAsyncComponent(() => import('./apps/IndelingForm.ts.vue')));
Vue.component('ReactieForm', defineDefaultAsyncComponent(() => import('./apps/ReactieForm.ts.vue')));
Vue.component('SslNieuwsberichten', defineDefaultAsyncComponent(() => import('./apps/SslNieuwsberichten.ts.vue')));
Vue.component('BetalingenOverzicht', defineDefaultAsyncComponent(() => import('./apps/BetalingenOverzicht.ts.vue')));
Vue.component('TrainingenOverzicht', defineDefaultAsyncComponent(() => import('./apps/TrainingenOverzicht.ts.vue')));
Vue.component('LeservaringOverzicht', defineDefaultAsyncComponent(() => import('./apps/LeservaringOverzicht.ts.vue')));


Vue.component('SslInputField', defineDefaultAsyncComponent(() => import('./fields/SslInputField.ts.vue')));
Vue.component('SslPasswordField', defineDefaultAsyncComponent(() => import('./fields/SslPasswordField.ts.vue')));


/**
 * ---------------------
 * Vee-validate
 * ---------------------
 */

import './validations';

// vue-js-modal

import VModal from 'vue-js-modal';

const VModalDefaults = {
  adaptive: true,
  height: 'auto',
  width: '90%',
  maxWidth: 600,
  scrollable: true,
};

Vue.use(VModal, {
  dialog: true,
  dynamic: true,
  dynamicDefaults: VModalDefaults,
});

/**
 * ---------------------
 * Make createVue method with default settings
 * ---------------------
 */

const createVueApp = (el = '#app', options = {}) => new Vue({
  el: el,
  name: 'VueApp',
  ...options,
});

export default createVueApp;

// Legacy mixin configuration

Vue.config.optionMergeStrategies.setup = (toVal: any, fromVal: any) => ( // eslint-disable-line @typescript-eslint/no-explicit-any
  (props: any, context: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    const from = fromVal !== undefined ? fromVal(props, context) : {};
    const to = toVal !== undefined ? toVal(props, context) : {};

    return {
      ...from,
      ...to,
    };
  });
