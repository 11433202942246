import {isValidIBAN} from 'ibantools';
import {extend} from 'vee-validate';
import {confirmed, email, is_not, max, min, required} from 'vee-validate/dist/rules';
import {DateFormatType, formatDate} from '../util/dates';

type FieldRef = `@${string}`;

extend('required', {
  ...required,
  message: 'Dit veld is verplicht.',
});

extend('min', {
  ...min,
  params: ['length'],
  message: 'Deze waarde mag niet korter zijn dan {length} karakters.',
});

interface MinValueParams {
  min: number;
}
extend('minValue', {
  validate: (value, params) => isNaN(value) ? false : (Number(value) >= (params as MinValueParams).min),
  params: ['min'],
  message: 'Deze waarde moet minimaal {min} zijn.',
});

extend('max', {
  ...max,
  params: ['length'],
  message: 'Deze waarde mag niet langer zijn dan {length} karakters.',
});

interface MaxValueParams {
  max: number;
}
extend('maxValue', {
  validate: (value, params) => isNaN(value) ? false : (Number(value) <= (params as MaxValueParams).max),
  params: ['max'],
  message: 'Deze waarde mag maximaal {max} zijn.',
});

extend('email', {
  ...email,
  message: 'Voer een geldig e-mailadres in.',
});

extend('iban', {
  validate: (value) => isValidIBAN(value),
  message: 'Voer een geldige IBAN in.',
});

extend('confirmed', {
  ...confirmed,
  message: 'De {target} velden komen niet overeen.',
});

interface DistinctParams {
  target: FieldRef;
}
extend('distinct', {
  validate: (value, params) => value !== (params as DistinctParams).target,
  params: ['target'],
  message: 'De velden {target} en {_field_} moeten verschillend zijn.',
});

extend('is_not', {
  ...is_not,
  message: 'Waarde mag niet gelijk zijn aan {other}.',
});

interface BeforeParams {
  date: Date;
}
extend('before', {
  validate: (value, params) => new Date(value) < new Date((params as BeforeParams).date),
  params: ['date'],
  message: (_, placeholders) => `Kies een datum voor ${formatDate(new Date(placeholders.date), DateFormatType.Long)}.`,
});

interface AfterParams {
  date: Date;
}
extend('after', {
  validate: (value, params) => new Date(value) > new Date((params as AfterParams).date),
  params: ['date'],
  message: (_, placeholders) => `Kies een datum na ${formatDate(new Date(placeholders.date), DateFormatType.Long)}.`,
});

interface RegexParams {
  exp: string | RegExp;
}
extend('regex', {
  validate: (value, params) => new RegExp((params as RegexParams).exp).test(value),
  params: ['exp'],
  message: 'Het formaat van het {_field_} veld is niet geldig.',
});
